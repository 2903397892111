@import "typography";
@import "variables";

@mixin shadow {
  box-shadow: 0 0.2em 0.35em 0 $color-box-shadow;
}

@mixin button {
  background-color: transparent;
  color: $color-primary-white;

  border: solid $color-primary-white 1px;
  border-radius: 2em;

  margin: 0;
  padding: 0.4em 1em;

  line-height: 1.3em;

  text-align: center;
  text-decoration: none;

  display: inline-block;
  box-sizing: border-box;
  min-width: 8em;

  cursor: pointer;
}

@mixin gold-button {
  @include font-regular;
  @include large;
  @include button;
  background-color: $color-button-trial !important;
  border: solid $color-button-trial 1px !important;
  color: $color-primary-main !important;
}

.btn {
  @include font-regular;
  @include large;
  @include button;

  &.action {
    color: $color-button-coral;
    border: solid $color-button-coral 1px;
  }
  &.trial {
    color: $color-button-trial;
    border: solid $color-button-trial 1px;
  }
  &.play {
    color: $color-button-play;
    border: solid $color-button-play 1px;
  }
  &.secondary {
    color: $color-icon-active;
    border: solid $color-icon-active 1px;
  }

  &.filled {
    @include shadow;
    &:hover {
      filter: brightness(95%);
    }
    color: $color-primary-white;
    min-width: 9.2em;

    &.action {
      background-color: $color-button-coral;
      border: solid $color-button-coral 1px;
    }
    &.trial {
      background-color: $color-button-trial;
      border: solid $color-button-trial 1px;
      color: $color-primary-main;
    }
    &.play {
      background-color: $color-button-play;
      border: solid $color-button-play 1px;
    }
    &.secondary {
      background-color: $color-icon-active;
      border: solid $color-icon-active 1px;
    }
    &.admin {
      background-color: $color-system-warning;
      border: solid $color-system-warning 1px;
    }
  }
}
