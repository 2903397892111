@import "~shared/style/reset";
@import "~shared/style/variables";
@import "~shared/style/typography";
@import "~shared/style/blocks";
@import "~shared/style/buttons";
@import "~shared/style/inputs";
@import "~shared/style/errors";
@import "~shared/style/ReactModal";
@import "~shared/style/widgets";
@import "~shared/style/cookies";

.app-body {
  padding: 1em 0 10em 0 !important;
  background: $color-primary-main;
}

.ReactModal__Overlay {
  z-index: 1000;
}
