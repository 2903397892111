@import "variables";
@import "typography";

html {
  font-size: 15px;
  line-height: 1.3em;
}

#root {
  margin: 0;
  padding: 0;

  background-image: url("~clients/components/landing/images/bg-mobile.jpg");

  @include md {
    background-image: url("~clients/components/landing/images/bg-desktop.jpg");
  }
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

body {
  margin: 0;
  padding: 0;
  color: white;
  background: transparent;
  line-height: 19px;
}

.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;

  &.loading {
    min-height: 20em;
  }

  > div {
    z-index: 3;
  }

  padding: 2em 0 6em 0;

  @include lg {
    padding: 0 0 10em 0;
  }

  .component-box-wrap {
    max-width: 100%;
  }

  .component-box {
    width: 90%;
    max-width: 566px;
    height: 371px;
    background: $gradient-component-box;
    border-radius: 0.8em;
    box-sizing: border-box;
  }

  .component-box-header {
    padding: 0 1.6em 1px 1.6em;
    margin-top: 1.6em;
    .image {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.wide {
    // admin pages
    .components-wrap {
      box-sizing: border-box;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;

      @include lg {
        width: 850px;
        padding-left: 0;
        padding-right: 0;
      }

      @include xl {
        width: 1040px;
      }
    }
  }

  .component-before {
    @include sm {
      width: 450px;
    }
    h1 {
      margin: 0;
      font-size: 1.7em;
      @include font-regular;
      line-height: 1.2em;
    }
    h2 {
      margin: 0;
      padding: 0;
      font-size: 1.1em;
    }
  }

  .component-after {
    margin-top: 0.5em;
    text-align: center;
    p {
      line-height: 1.7em;
    }
    a {
      color: $color-button-link;
      text-decoration: none;
      &:hover {
        color: lighten($color-button-link, 10%);
      }
    }
  }
}

.scrollable {
  max-height: 30rem;
  overflow-x: scroll;
}

.p-1 {
  padding: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4em;
}

.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4em;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}
