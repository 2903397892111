@import "../../../shared/style/variables";
@import "../../../shared/style/typography";

.search-bar-container {
  display: flex;
  background-image: linear-gradient(to bottom, #ffd355, #e66400);
  border: solid #f59600 1px;
  border-radius: 14px;
  margin-bottom: 1em;

  box-sizing: border-box;
  padding: 1em;

  .search-bar-input {
    border-radius: 18px;
    border: solid #dbcfc4 2px !important;
    background-color: #ededed;
    border: none;
    padding: 5px 10px 5px 20px;
    margin-right: 15px;
    height: 26px;
    flex: 1;
    color: black;
    margin-bottom: 0;
  }

  .search-button {
    padding: 0;
    font-size: 20px;
    @include font-bold;
    color: #fb6800;
    min-width: 124px;
    height: 40px;
    margin-bottom: 0;
    border-radius: 18px;
    background-color: #ededed;
    background-image: none;
    border: solid #dbcfc4 2px;
  }

  .search-button:hover {
    background-image: none;
    border: solid #827e7a 2px;
  }
}
