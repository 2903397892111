@import "variables";
@import "typography";

.Toastify__toast {
  border-radius: 7px;
  min-height: 77px;
  width: 301px;
  .Toastify__toast-body {
    padding-left: 12px;
  }
  @include font-regular;
}
.Toastify__toast--success {
  background-color: $color-system-success;
}
.Toastify__toast--warning {
  background-color: $color-system-warning;
}
.Toastify__toast--error {
  background-color: $color-system-alert;
}
