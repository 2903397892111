@import "~shared/style/variables";

.error-page {

  h1  {
    font-size: 2.5em;
  }

  h2 {
    padding-left: 2em;
    padding-right: 2em;
    font-size: 1.7em; line-height: 1.5em;
  }

  .sleeping-moshi {
    width: 13em;
    margin-bottom: 7em;
    margin-top: 5em;
  }

  @include sm {
    h1  {
      font-size: 2.5em;
    }

    h2 {
      font-size: 2em; line-height: 1.5em;
    }

    .sleeping-moshi {
      width: 10em;
      margin-bottom: 7em;
      margin-top: 5em;
    }

  }


  & + footer:after {
    display: none;
  }
}
