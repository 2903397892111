@import "~shared/style/variables";

.danger {
  background-color: $color-system-alert !important;
  h1 {
    color: $color-primary-white !important;
  }

  .border {
    height: 100%;
    width: 100%;
    border-radius: 0.8em;
    background: linear-gradient(
        90deg,
        $color-button-trial 50%,
        $color-text-grey 50%
      ),
      linear-gradient(90deg, $color-button-trial 50%, $color-text-grey 50%),
      linear-gradient(0deg, $color-button-trial 50%, $color-text-grey 50%),
      linear-gradient(0deg, $color-button-trial 50%, $color-text-grey 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px;
    animation: border-dance 30s infinite linear;
  }
}
@keyframes border-dance {
  0% {
    background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px;
  }
  100% {
    background-position: 100% 0px, 0px 100%, 0px 0px, 100% 100%;
  }
}
