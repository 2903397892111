@import "variables";

@mixin small {
  font-size: 13px;
}

@mixin regular {
  font-size: 1em;
}

@mixin large {
  font-size: 1.2em;
}

@mixin x-large {
  @include sm {
    font-size: 1.6em;
  }
  @include md {
    font-size: 1.8em;
  }
}

.small {
  @include small;
}

.regular {
  @include regular;
}

.large {
  @include large;
}

.x-large {
  @include x-large;
}

@mixin font-light {
  font-weight: 300;
}
@mixin font-regular {
  font-weight: 400;
}
@mixin font-bold {
  font-weight: 600;
}
@mixin font-black {
  font-weight: 900;
}
@mixin font-black-italic {
  font-weight: 900;
  font-style: italic;
}

.font-light {
  @include font-light;
}

.font-regular {
  @include font-regular;
}

.font-bold {
  @include font-bold;
}

.font-black {
  @include font-black;
}

.font-black-italic {
  @include font-black;
}


body {
  @include font-regular;
  font-family: Lato, sans-serif;
  font-size: 15px;
  letter-spacing: 0.21px;
  line-height: 19px;
}
p {
  font-size: 18px;
  @include md {
    font-size: 15px;
  }
}
h1, h2{
  @include font-regular;
  color: $color-primary-white;
  font-family: Lato, sans-serif;
  font-size: 28px;
  @include md {
    font-size: 24px;
  }
  letter-spacing: 0.3px;
  line-height: 33px;
  text-align: center;
}

h3 {
  margin-top: 1.6em;
  text-align: center;
}

a, button.link {
  color: $color-button-link;
  text-decoration: none;

  cursor: pointer;
  display: inline;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;

  &:hover {
    color: lighten($color-button-link, 10%);
  }
  &.text-underlined {
    text-decoration: underline;
  }
  &.pill {
    background-color: rgba($color-primary-main, 0.6);
    margin-left: 0.5em;
    padding: 0.4em 2em 0.6em 2em;
    border-radius: 1em;
  }
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 600 !important;
}