/*
  Colors
 */
$color-primary-dark: #05073c;
$color-primary-main: #3b338a;
$color-primary-light: #9d74c2;
$color-primary-white: #ffffff;

$color-brand-royal: #321a72;
$color-brand-coral: #f46363;

$color-text-grey: #2c2c2d;
$color-text-error: #f46363;

$color-button-trial: #f8cf03;
$color-button-play: #fb7f04;
$color-button-link: #00bafd;
$color-button-coral: #f46363;
$color-button-disabled: #cccccc;

$color-icon-active: #00bafd;
$color-icon-grey: #2c2c2d;
$color-icon-default: #ffffff;

$color-tag-free: #c7a2fb;
$color-tag-new: #f46363;

$color-system-alert: #f46363;
$color-system-warning: #fb7f04;
$color-system-success: #14a441;

$color-box-shadow: #0000004f;

$color-gradient-dark: #5459aa;
$color-gradient-mid: #3060b2;
$color-gradient-light: #089de3;

/*
* Gradients
*/

$gradient-component-box: linear-gradient(
  to bottom left,
  $color-gradient-dark 0%,
  $color-gradient-mid 51%,
  $color-gradient-light 100%
);

/*
* Grid
*/

// smallest phones
$screen-xsm-max: 320px;
@mixin xsm {
  @media (max-width: $screen-xsm-max) {
    @content;
  }
}

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 321px;
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small tablets (portrait view)
$screen-md-min: 661px;
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Tablets and small desktops
$screen-lg-min: 1126px;
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Large tablets and desktops
$screen-xl-min: 1200px;
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Short screen heights lg or xl
$screen-short-max: 800px;
@mixin short {
  @media (max-height: #{$screen-short-max}) {
    @content;
  }
}
