@import "variables";

.ReactModal__Overlay {
  width: 100%;
  background-color: rgba($color-primary-dark, 0.8) !important;
  z-index: 1000;
}
.Modal-big {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  background-color: #3e3494;
  border-radius: 25px;
  padding: 2rem;
  border: 3px solid white;
  outline: none;

  h2 {
    position: relative;
    .close {
      position: absolute;
      right: 1rem;
      top: -1rem;
      cursor: pointer;
    }
  }
}

.Modal-small {
  position: absolute;
  @include xsm {
    top: 30%;
    margin: 0 0.4em;
    padding: 0 0.2em;
  }
  @include sm {
    top: 50%;
    padding: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include md {
    top: 36%;
    padding: 35px;
  }
  background-color: $color-primary-white;
  h2 {
    color: $color-primary-main;
  }
  border-radius: 11px;
  text-align: center;
  color: $color-primary-main;
  table {
    text-align: left;
  }
}

.ReactModalPortal {
  h2 {
    line-height: 1.2em;
  }
  .actions.buttons {
    display: flex;
    justify-content: center;
    button {
      min-width: 7rem;
      margin: 0 0.2rem;
    }
    @include md {
    }
  }
}
