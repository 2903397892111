@import "../../style/variables";
@import "../../style/typography";

.user-page {
  .component-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 1.5em;
    h1,
    h2 {
      margin-top: 2em;
    }
    h1,
    h2,
    h3,
    label {
      @include font-regular;
      height: 100%;
      color: $color-primary-main;
    }
    background: $color-primary-white;
  }

  .content {
    padding: 0 2.2em;
    .row {
      .data-group {
        padding-bottom: 2em;
      }
      display: flex;
      flex-direction: row;
      .right {
        margin-left: auto;
      }
    }
    .data-group {
      margin: auto 0;
      .device {
        @include font-regular;
        color: $color-primary-main;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0.23px;
      }
      .value {
        @include font-regular;
        color: $color-primary-main;
        height: 22px;
        font-size: 18px;
        letter-spacing: 0.23px;
        padding-top: 3px;
        padding-bottom: 21px;
      }
    }

    .btn {
      margin: 0.4em auto;
      min-width: 111px !important;
      width: 111px;
      &.wide {
        width: 220px !important;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
    }

    .form-control {
      background-color: #f2f2f2;
    }

    .checkbox {
      @include font-regular;
      font-size: 18px;
    }

    .fine-print {
      text-align: left;
      color: $color-primary-main;
    }

    .hidden {
      display: none;
    }

    .cloud {
      .background {
        path {
          fill: rgba($color-primary-dark, 0.8);
        }
      }
      path {
        fill: $color-primary-dark;
      }
    }
  }

  input[type="checkbox"] + span {
    width: 24px;
    height: 25px;
    border-radius: 2px;
    border: 1.5px solid $color-text-grey;
  }
  input[type="checkbox"]:checked + span {
    border-radius: 2px;
    border: 1.5px solid $color-text-grey;
  }

  [type="checkbox"]:not(checked) + span:after,
  [type="checkbox"]:checked + span:after {
    color: white;
    content: url(/media/Terms@1.5x.svg);
    position: absolute;
    left: -29%;
    top: 18%;
  }

  [type="checkbox"]:not(:checked) + span:after {
    opacity: 0;
  }

  [type="checkbox"]:checked + span:after {
    opacity: 1;
  }

  [type="checkbox"][disabled=""]:not(:checked) + span {
    border: 1.5px solid $color-button-disabled;
    background: $color-button-disabled;
  }
}

.responsive-table.subscription-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  justify-content: space-evenly;
  .wrapper {
    overflow: scroll;
    flex: 1;
    table {
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      align-content: center;
      th {
        text-align: left;
        white-space: nowrap;
      }
      tr:hover {
        background: #3e2694;
      }
      td {
        white-space: nowrap;
        padding: 0.3em 0.3em;
      }
      tbody {
        overflow: scroll;
      }
    }
  }
}

.Modal-small {
  h2 {
    text-align: left;
  }
  .label {
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .value {
    input,
    select {
      width: 100%;
      max-width: 30rem;
      outline: 0;
      border-width: 0 0 2px;
      border-color: #c0c1ff;
      background-color: transparent;
      color: #c0c1ff;
      @include font-regular;
      font-size: 20px;
      line-height: 1.2em;
    }
  }
}
