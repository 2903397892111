@import "~shared/style/variables";
@import "~shared/style/typography";

.campaign-page {
  .component-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 1.5em;
    h1,
    h2 {
      margin-top: 2em;
    }
    h1,
    h2,
    h3,
    label {
      @include font-regular;
      height: 100%;
      color: $color-primary-main;
    }
    .component-box-header {
      display: flex;
      flex-direction: row;
      .btn {
        margin-left: auto;
        max-width: 7em;
        align-self: center;
      }
    }
    background: $color-primary-white;
  }

  .section {
    &,
    .with-loading-block {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding: 0;
      @include lg {
        flex-wrap: nowrap;
      }
    }

    .content {
      flex-grow: 3;
      padding: 0 1.6em;
      .form-group,
      .data-group {
        margin-top: 2rem;
        :last-of-type {
          margin-bottom: 2em;
        }
        .label {
          @include font-regular;
          color: $color-primary-main;
        }
        .value {
          @include font-regular;
          color: $color-primary-main;
          height: 22px;
          font-size: 18px;
          letter-spacing: 0.23px;
          padding-top: 3px;
          padding-bottom: 21px;

          input,
          select {
            width: 100%;
            max-width: 30rem;
            outline: 0;
            border-width: 0 0 2px;
            border-color: $color-primary-main;
            background-color: transparent;
            color: $color-primary-main;
            @include font-regular;
            font-size: 20px;
            line-height: 1.2em;
            &:disabled {
              border-color: $color-button-disabled;
              color: $color-button-disabled;
            }
          }
        }
      }
    }
    .actions {
      padding: 1em 1em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: column;

      .cloud {
        .background {
          path {
            fill: rgba($color-primary-dark, 0.8);
          }
        }
        path {
          fill: $color-primary-dark;
        }
      }
    }
  }

  h4 {
    font-size: 0.6em;
    float: right;
  }

  .component-box {
    display: flex;
    flex-direction: column;
    height: 100%;

    z-index: inherit;
    max-width: 400px;

    @include md {
      max-width: 900px;
    }
  }
  .list-table {
    color: $color-primary-main;
    padding: 1.6em;
    tr:hover {
      background-color: $color-button-disabled;
    }
  }
}
