
.campaigns-page {
  h1 {
    margin-bottom: 4rem;
    .actions {
      float: right;
      font-size: 1rem;
    }
  }
}
