@import "../../../shared/style/variables";

header.app-header {
  min-height: 58px;
  position: relative;

  .container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
  }

  .header-logo {
    flex: auto;
    display: flex;
    min-width: 0;
    text-align: center;
    img {
      max-width: 140px;
    }
  }

  .header-links {
    display: none;
    flex: auto;
    min-width: 0;
    padding: 1.8em 2.9em;
    a {
      color: $color-primary-white;
    }
  }

  .header-actions {
    display: none;
    overflow: hidden;
    transition: max-height 0.5s ease;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .mobile-menu-toggle {
    position: absolute;
    right: 0;
    top: 0;
    padding: 25px 20px;
    font-size: 30px;
    cursor: pointer;
  }

  &.is-open {
    .header-links {
      display: flex;
    }
    .header-actions {
      max-height: 1000px;
      display: flex;
      :first-child {
        margin: 0;
      }
      margin: auto;
    }
  }

  @include xsm {
    padding: 0.734em 0 0;
    .header-logo {
      margin: auto;
    }
    .header-links {
      margin: auto;
      padding: 0.5em 1em;
      a {
        padding-right: 0.8em;
      }
      :last-child {
        padding-right: 0;
      }
    }

    .header-actions {
      .btn {
        margin-left: 0.2em;
      }
    }
  }

  @include sm {
    padding: 2.734em 0.533em 0 0.533em;

    .header-logo {
      margin: auto;
    }

    .header-links {
      margin: auto;
      a {
        padding-right: 0.8em;
      }
      :last-child {
        padding-right: 0;
      }
    }

    .header-actions {
      padding-top: 1em;
      .btn {
        margin-left: 1.9em;
      }
    }
  }

  @include md {
    padding: 0;

    .container {
      padding: 2em 2em 0 2em;
      flex-direction: row;
    }

    .header-logo {
      flex: none;
      text-align: left;
      img {
        width: 250px;
      }
    }

    .header-links {
      display: block;
      padding-right: 0.4em;
      a {
        padding-right: 0.8em;
      }
    }

    .header-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: center;
      align-content: center;
      padding-top: 0;
      .btn {
        min-width: 0;
        font-size: 1em;
        margin-left: 0.9em;
      }
    }

    .mobile-menu-toggle {
      display: none;
    }
  }

  @include lg {
    padding: 0 0 1em 0;

    .header-links {
      padding: 1.8em 2.9em;
      text-align: right;
      a {
        padding-right: 2.4em;
      }
    }

    .header-actions {
      .btn {
        min-width: 8em;
        font-size: 1.2em;
      }
    }
  }
}

/** Admin Header Menu */
nav.header-menu {
  text-align: center;
  background-color: $color-primary-main;
  color: white;
  padding: 7px 0;
  box-shadow: 3px 19px 5px 20px $color-box-shadow;
  a {
    padding: 0 7px;
    color: $color-button-link;
    text-decoration: none;

    &:hover,
    &.is-active {
      color: white;
    }
  }
}
