@import "../../style/variables";
@import "../../style/typography";

.Modal-small {
  @include sm {
    width: 85%;
  }
  @include md {
    width: calc(566px - 70px);
  }
  display: flex;
  flex-direction: column;
  .title {
    @include font-bold;
    color: $color-primary-main;
    text-align: center;
  }
  .description {
    @include sm {
      padding: 0;
    }
    padding: 0 2em 0 2em;
    color: $color-primary-main;
  }
  .buttons {
    @include sm {
      margin: 30px 0 22px 0;
    }
    margin: 49px 0 27px 0;
    align-items: center;
  }
}
