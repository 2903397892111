@import "~shared/style/variables";

.container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $color-primary-main;
  .header {
    display: flex;
    flex-direction: row;
    .date-added {
      margin-left: auto;
    }
  }
  .comments {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    color: $color-primary-main;
    .date-added {
      font-size: 12px;
    }
    .comment {
      font-size: 15px;
      padding-top: 3px;
      padding-bottom: 21px;
    }
  }
  .actions {
    padding-bottom: 41px;
  }
}
.important {
  font-size: 18px !important;
  color: $color-system-alert !important;
}
